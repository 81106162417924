var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',[_c('field',{staticStyle:{"top":"9px","left":"10px"},attrs:{"name":"modelo","widget":"select","options":{
          'empresa':'empresa',
          'tesoreria':'tesoreria',
          'ubicacion_tesoreria':'ubicacion_tesoreria',
          'delegacion':'delegacion',
          'almacen':'almacen',
          'proveedor':'proveedor',
          'movimiento_proveedor':'movimiento_proveedor',
          'cliente':'cliente',
          'tipo_cliente':'tipo_cliente',
          'movimiento_cliente':'movimiento_cliente',
          'representante':'representante',
          'comision':'comision',
          'seccion':'seccion',
          'familia':'familia',
          'modelo':'modelo',
          'articulo':'articulo',
          'coleccion':'coleccion',
          'naturaleza':'naturaleza',
          'talla':'talla',
          'color':'color',
          'pureza':'pureza',
           'marca':'marca',
          'atributo':'atributo',
          'moneda':'moneda',
          'provincia':'provincia',
          'pais':'pais',
          'metal':'metal',
          'tipo_doc':'tipo_doc',
          'forma_pago':'forma_pago',
          'coste_adicional':'coste_adicional',
          'coste_reparacion':'coste_reparacion',
          
          'deposito_representante':'deposito_representante',
          'imagen_articulo':'imagen_articulo',
          'imagen_proveedor':'imagen_proveedor',
          'imagen_cliente':'imagen_cliente',
          'plantilla_informe':'plantilla_informe',
          'plantilla_etiqueta':'plantilla_etiqueta',

          'factura_proveedor':'factura_proveedor',
          'pedido_proveedor':'pedido_proveedor',
          'albaran_proveedor':'albaran_proveedor',
          'factura_cliente':'factura_cliente',
          'pedido_cliente':'pedido_cliente',
          'albaran_cliente':'albaran_cliente',

          'reparacion':'reparacion',
          'configuracion':'configuracion',
          'usuario':'usuario',
          'grupo_usuario':'grupo_usuario',
          'bd':'bd',
          'log':'log',
          'secuencia':'secuencia'
          },"searchable":"","label":"Modelo","width":"120px","readonly":_vm.mode == 'edit'},on:{"change":_vm.rellenarTabla}}),_c('field',{staticStyle:{"top":"9px","left":"140px"},attrs:{"name":"descripcion","widget":"char","searchable":"","label":"Descripción","placeholder":"Descripción","width":"350px"}}),_c('field',{staticStyle:{"top":"60px","left":"10px","width":"648px"},attrs:{"name":"campos","widget":"handsontable","label":"Campos","height":265,"minRows":12,"columns":[
          {name:'activo',header:'Act.', widget: 'checkbox'},
          {name:'campo',header:'Campo', readOnly: true},
          {name:'nombre',header:'Nombre'},
          {name:'descripcion',header:'Descripción'}
      ]}})],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }