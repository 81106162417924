<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article>
        <field
          name="modelo"
          widget="select"
          :options="{
            'empresa':'empresa',
            'tesoreria':'tesoreria',
            'ubicacion_tesoreria':'ubicacion_tesoreria',
            'delegacion':'delegacion',
            'almacen':'almacen',
            'proveedor':'proveedor',
            'movimiento_proveedor':'movimiento_proveedor',
            'cliente':'cliente',
            'tipo_cliente':'tipo_cliente',
            'movimiento_cliente':'movimiento_cliente',
            'representante':'representante',
            'comision':'comision',
            'seccion':'seccion',
            'familia':'familia',
            'modelo':'modelo',
            'articulo':'articulo',
            'coleccion':'coleccion',
            'naturaleza':'naturaleza',
            'talla':'talla',
            'color':'color',
            'pureza':'pureza',
             'marca':'marca',
            'atributo':'atributo',
            'moneda':'moneda',
            'provincia':'provincia',
            'pais':'pais',
            'metal':'metal',
            'tipo_doc':'tipo_doc',
            'forma_pago':'forma_pago',
            'coste_adicional':'coste_adicional',
            'coste_reparacion':'coste_reparacion',
            
            'deposito_representante':'deposito_representante',
            'imagen_articulo':'imagen_articulo',
            'imagen_proveedor':'imagen_proveedor',
            'imagen_cliente':'imagen_cliente',
            'plantilla_informe':'plantilla_informe',
            'plantilla_etiqueta':'plantilla_etiqueta',

            'factura_proveedor':'factura_proveedor',
            'pedido_proveedor':'pedido_proveedor',
            'albaran_proveedor':'albaran_proveedor',
            'factura_cliente':'factura_cliente',
            'pedido_cliente':'pedido_cliente',
            'albaran_cliente':'albaran_cliente',

            'reparacion':'reparacion',
            'configuracion':'configuracion',
            'usuario':'usuario',
            'grupo_usuario':'grupo_usuario',
            'bd':'bd',
            'log':'log',
            'secuencia':'secuencia'
            }"
          searchable
          label="Modelo"
          width="120px"
          :readonly="mode == 'edit'"
          style="top:9px; left:10px;"
          @change="rellenarTabla"
        />
        <field
          name="descripcion"
          widget="char"
          searchable
          label="Descripción"
          placeholder="Descripción"
          width="350px"
          style="top:9px; left:140px;"
        />
        <field
          name="campos"
          widget="handsontable"
          label="Campos"
          :height="265"
          :minRows="12"
          style="top:60px; left:10px; width: 648px;"
          :columns="[
            {name:'activo',header:'Act.', widget: 'checkbox'},
            {name:'campo',header:'Campo', readOnly: true},
            {name:'nombre',header:'Nombre'},
            {name:'descripcion',header:'Descripción'}
        ]"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Base de datos",
      dbAdapter: "bd",
      primary: "modelo"
    };
  },
  methods: {
    rellenarTabla() {
      var self = this;
      if (self.mode != "new" || !self.formData.modelo) return;
      window.DB.action(self.formData.modelo, "getAllFields").then(function(
        res
      ) {
        self.$set(
          self.formData,
          "campos",
          res.data.map(function(el) {
            return {
              campo: el.name
            };
          })
        );
      });
    }
  }
};
</script>
